<script>
import Layout from "../../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            history: [],
        };
    },
    methods: {
        async getHistory() {
            const response = await axios.get(
                "https://api.doctosoft.com/index.php/api/medical/history/"
            );
            this.history = response.data;
        },
        async deleteHistory(id) {
            await axios.delete(
                "https://api.doctosoft.com/index.php/api/medical/history/" + id
            );
            this.getHistory();
        },
        addButton() {
            this.$router.push({
                path: '/admin/add-prescription'
            });
        },
        updateHistory(id) {
            if (id) {
                this.$router.push("/admin/update-prescription/" + id);
            } else {
                this.$router.push("/admin/add-prescription")
            }
        },
        confirm(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value === true) {
                    Swal.fire("Deleted!", "Deleted a prescription successfully with id " + id, "success");

                    this.deleteHistory(id)

                }
            });
        },
    },
    created() {
        this.getHistory();
    }
};
</script>
<template>
    <Layout>
        <b-row>
            <b-col lg="12">
                <b-card no-body id="historyTable">
                    <b-card-header class="d-flex align-items-center">
                        <h5 class="card-title flex-grow-1 mb-0">Medical History Table</h5>
                        <div class="d-flex gap-1 flex-wrap">
                            <!--< b - button variant =" soft-danger" id="remove-actions" onClick="deleteMultiple()"><i
                    class="ri-delete-bin-2-line"></i>
                  </b-button>-->
                            <b-link class="btn btn-success button create-btn" type="button" id="roleadd-btn"
                                @click="addButton"><i class="ri-add-line align-bottom me-1"></i>
                                Add </b-link>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <div>
                            <div class="table-responsive table-card mb-3">
                                <table class="table align-middle table-nowrap mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" style="width: 50px;">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="checkAll"
                                                        value="option">
                                                </div>
                                            </th>
                                            <th class="sort" data-sort="doctor" scope="col">Doctor</th>
                                            <th class="sort" data-sort="user" scope="col">User</th>
                                            <th class="sort" data-sort="date" scope="col">Date</th>
                                            <th class="sort" data-sort="note" scope="col">Note</th>
                                            <!-- <th class="sort" data-sort="diseases" scope="col">Diseases</th>
                                            <th class="sort" data-sort="medicines" scope="col">Medicines</th> -->
                                            <th scope="col">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="list form-check-all">
                                        <tr v-for="his in history" :key="his.id">
                                            <th scope="row">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="chk_child"
                                                        value="option1">
                                                </div>
                                            </th>
                                            <td class="doctor">{{ his.doctor }}</td>
                                            <td class="user">{{ his.user }}</td>
                                            <td class="date">{{ his.date }}</td>
                                            <td class="note">{{ his.note }}</td>
                                            <!-- <td class="diseases">{{ his.diseases }}</td> -->
                                            <!-- <td class="medicines">{{ his.medicines }}</td> -->
                                            <div class="dropdown">
                                                <b-button variant="soft-secondary" size="sm" class="dropdown" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ri-more-fill align-middle"></i>
                                                </b-button>
                                                <ul class="dropdown-menu dropdown-menu-end">
                                                    <li>
                                                        <b-link class="dropdown-item edit-item-btn" id="edit-item-btn"
                                                            @click="(updateHistory(his.id))">Edit</b-link>
                                                    </li>
                                                    <li>
                                                        <b-button class="dropdown-item remove-item-btn"
                                                            @click="confirm(his.id)">Delete</b-button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
</Layout></template>
